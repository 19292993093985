body {
  background-color: #282c34;
  color: rgb(50, 131, 142);
  padding: 40px;
  font-family: Sans-Serif;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.custom-tooltip {
  background-color: #282c3480;
  font: white;
  border: 0;
  padding: 10px;
  border-radius: 25px;
}